import React, { Component,useState,useEffect } from 'react';
import { Avatar, Drawer, Divider, Button,Switch,Tag } from 'antd';
import { 
	MobileOutlined, 
	MailOutlined, 
	UserOutlined, 
	CompassOutlined,
	CalendarOutlined,
	FacebookOutlined,
	InstagramOutlined,
	TwitterOutlined,
	GlobalOutlined,
	CloseOutlined, CheckOutlined
} from '@ant-design/icons';
import Register from './addCustomer';
import { connect } from "react-redux"
import { ChangeCustomerStatus } from "../../actions/customerAction"
import { TextCapitalize, usaFormatNumber } from '../../utils/helper';
const UserView =(props)=> {
		const { data, visible, close} = props;
		const changeStatus=(value)=>{
			const status={customerId:data?.clients_cust_id,status:value===0?1:0}
			props.ChangeCustomerStatus(status)
		}
		const closePageHandler=()=>{
			close();
		}
		const customerViewData=[
			{id:1,head:"Name",details:data?.name},
			{id:2,head:"Customer Id",details:data?.clients_cust_id},
			{id:3,head:"Phone No",details:usaFormatNumber(data?.phone)},
			{id:4,head:"Email",details:data?.email? data?.email: '-'},
			{id:5,head:"Zip Code",details:data?.zipcode? data?.zipcode: '-'},
			{id:6,head:"Address",details:data?.street_address? data?.street_address: '-'},
			{id:7,head:"Status",details:
			<div style={{display:"flex",padding:"10px 0px"}}>
				<Switch style={{background:data?.status?"#0FA368":"#FD8F46"}} loading={props.loading} onClick={()=>changeStatus(data.status)} checked={data?.status} checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
			    <div style={{marginLeft:"10px"}}>
                {(()=>{
                 if(data?.status===1){
                   return <span style={{padding:"0px 6px",borderRadius:"2px",background:"#0FA36824",color:"#0FA368"}}>Active</span>
                }else{
                  return <span style={{padding:"0px 6px",borderRadius:"2px",background:"#FD8F461A ",color:"#FD8F46"}}>Inactive</span>
                }
                })()}
                </div>
		    </div>},
		]
		return (
			<Drawer
				width={300}
				placement="right"
				onClose={closePageHandler}
				closable={false}
				visible={visible}
				title={<div className='wash_yard_drawer_head' style={{display:"flex",alignItems:"center",justifyContent:"space-between",padding:"10px 0px"}}>
					<span>customer details</span>
					<span style={{cursor:"pointer"}} onClick={closePageHandler}><CloseOutlined /></span>
					</div>}
			>
            <div className='wash_customer_views'>
				{customerViewData.map((item)=>{
					return <div key={item.id} className='wash_customer_views_text' style={{margin:item.id===7?"16px 0px":""}}>
					        <span className='wash_customer_views_text_left'>{item.head}:</span>
					        <span className='wash_customer_views_text_right' style={{color:item.id===4?"#38B6FF":""}}>{item.details}</span>
				            </div>
				})}
			</div>
			<div className='wash_customer_border'></div>
			<div className='wash_delete_edit_customer'>
			<div className='wash_delete_customer'>delete</div>
			{data?.status !== 0&&
				<Register custom={true} className="wash_edit_customer" clients_cust_id={data?.clients_cust_id} {...props} Button={<span>Edit</span>} title="Edit Customer"/>
			}
			</div>
			</Drawer>
		)
}


const mapStateToProps = state => ({
	loading:state.customer.loading,
	customer_status:state.customer.customer_status
  })
  
  export default connect(mapStateToProps, { ChangeCustomerStatus })(UserView)